import React, { useState } from "react";
import Clipboard from "react-clipboard.js";

function App() {
  let [time, currentTime] = useState(new Date().toLocaleTimeString());

  function updateTime() {
    currentTime(new Date().toLocaleTimeString());
  }

  const [buttonText, setButtonText] = useState("Get Time");

  function copy() {
    setButtonText("Time was copied");
  }

  setInterval(updateTime, 1000);

  return (
    <div className="container">
      <h1 id="time">{time}</h1>
      <Clipboard data-clipboard-text={time}>
        <button onClick={copy}>{buttonText}</button>
      </Clipboard>
    </div>
  );
}

export default App;
